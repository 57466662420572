<template>
	<section id="editor-features" class="container-fluid p-0 py-5 my-5">
		<div id="bg-image" :style="`background-image: url(${activeFeature.image_src});`"></div>
		<div class="col-10 offset-1 mt-5" id="editor-text">
			<div class="row my-6" id="top">
				<div class="col-3">
					<h2>Editor Features</h2>
				</div>
				<div class="offset-3 col-sm-6 col-lg-4">
					<p>{{ activeFeature.description }}</p>
				</div>
			</div>

			<div class="row mt-9 text-center" @mouseenter="mouseIn = true" @mouseleave="mouseIn = false">
				<div class="col-2 feature" v-for="(feature, idx) in features" :key="idx" @click="activeIdx = idx">
					<hr>
					<div class="diode" :class="`${activeIdx === idx ? 'active' : ''}`"></div>
					<h5 class="small">{{ feature.title }}</h5>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "EditorFeaturesSection",
	data() {
		return {
			mouseIn: false,
			slider: null,
			activeIdx: 0,
			features: [
				{
					title: "Metaverse Creation",
					image_src: "/images/features/metaverse.jpg",
					description: "Our simple, intuitive drag-and-drop builder will enable you to create your metaverse quickly and easily - with no coding experience required! ",
				},
				{
					title: "Interactables",
					image_src: "/images/features/editor.jpg",
					description: "Bring your assets to life by adding custom interactions to any imported object in our platform",
				},
				{
					title: "Multiplayer",
					image_src: "/images/features/multiplayer.jpg",
					description: "Multiplayer enabled from the start. Build your dream virtual world and invite all your friends to hang out!",
				},
				{
					title: "Social Login",
					image_src: "/images/features/social.jpg",
					description:
							"All logins supported, connect a web3 wallet for easy NFT imports and transactions. Prefer the old ways? Anybody can access the metaverse with a simple email or social login.",
				},
				{
					title: "Mobile Compatible",
					image_src: "/images/features/mobile.jpg",
					description: "Your metaverse can run on any device! Connect from a mobile, PC or tablet and access your virtual world from anywhere!",
				},
				{
					title: "Entity System",
					image_src: "/images/features/entity.jpg",
					description: "Every separate object placed in your scene can be controlled uniquely via interactions.",
				},
			]
		}
	},
	computed: {
		activeFeature: function () {
			return this.features[this.activeIdx]
		}
	},
	methods: {
		slideThrough: function() {
			if (this.mouseIn)
				return

			let idx = this.activeIdx + 1
			if (idx >= this.features.length)
				idx = 0
			this.activeIdx = idx
		}
	},
	mounted() {
		this.slider = setInterval(() => this.slideThrough(), 5000)
	},
	beforeDestroy() {
		clearInterval(this.slider)
	}
}
</script>

<style scoped>
#editor-features {
	position: relative;
}
#top {
	min-height: 200px;
}

#bg-image {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 900px;
	filter: brightness(30%);
	transition: background 0.5s linear;
}

#editor-text {
	position: absolute;
	top: 0;
	left: 0;
}

hr {
	color: white;
	height: 6px;
	opacity: 1;
	margin-top: 0;
}

.diode {
	position: absolute;
	top: 0;
	left: 47%;
	width: 20px;
	height: 20px;
	background: white;
	border-radius: 33px;
	margin-top: -7px;
}

.my-6 {
	margin-top: 8rem !important;
	margin-bottom: 8rem !important;
}

.mt-9 {
	margin-top: 12rem !important;
}

.feature * {
	transition: all 0.3s ease-in-out;
}

.feature:hover {
	cursor: pointer;
	color: var(--link-primary);
}

.feature:hover .diode, .diode.active {
	background: var(--link-primary);
}

.feature:hover *:not(hr) {
	color: var(--link-primary);
}

.col-2.feature {
	position: relative;
	padding-left: 0;
	padding-right: 0;
}
</style>