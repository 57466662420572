<template>
	<section id="community" class="container-fluid text-center">
		<div id="image-container" class="col-10 offset-1 p-5 bg-primary">
			<div id="text-container">
				<div class="row my-5 align-content-center" id="content-container">
					<div class="col-sm-12 col-lg-8 text-center offset-0 offset-lg-2">
						<h2>Get updates on industry innovations and the latest news about the Metaverse</h2>
					</div>
					<div class="col-sm-12 col-md-12 col-lg-6 offset-0 offset-md-0 offset-lg-3 mt-5">
						<div class="input-group input-group-lg">
							<input placeholder="Enter email address" class="form-control form-control-lg">
							<button class="btn btn-primary">Subscribe</button>
						</div>

						<p class="mt-5">By submitting your information, you are agreeing to receive news, surveys and special offers from AlphaBatem Labs.
							<router-link to="/privacy">Privacy Policy</router-link>
						</p>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
export default {
	name: "CommunitySection"
}
</script>

<style scoped>
.links a {
	color: #fff;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

.links a:hover span {
	text-decoration: underline;
}

.links a:hover {
	filter: drop-shadow(0 0 0.5rem #ffffff);
}

.form-control {
	margin-right: 2%;
}

input.form-control::placeholder {
	color: white;
}

#text-container {
	background-image: url("/images/blobs/metaverse_blob.png") !important;
	background-repeat: no-repeat !important;
	min-height: 720px;
}

#content-container {
	padding-top: 6%;
}

@keyframes blobUp {
	0% {
		background-position: 0 250%;
	}

	100% {
		background-position: 0 0;
	}
}

.show #image-container #text-container {
	background-position: 25% 100% !important;
	background-size: 70% !important;
}

#text-container {
	transition-delay: 0.3s !important;
	transition: all 1s ease;
	background-size: 40% !important;
	background-position: 30% 150% !important;
}

</style>