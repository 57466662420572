<template>
	<section id="features" class="container py-5">
		<div class="row mb-5">
			<h2 class="mt-5">Featured Product</h2>
		</div>

		<div class="row mb-5">
			<div class="col-sm-12 col-md-7 mb-5 mb-md-0">
				<h3 class="animate__animated animate__fadeInUp animate__delay-1s">{{ activeFeature.name }}</h3>

				<p class="animate__animated animate__fadeIn animate__delay-2s mb-5 mt-4">{{ activeFeature.description }}</p>

				<img class="img-fluid" alt="" :src="activeFeature.image">
			</div>

			<div class="col offset-1">
				<div class="row mb-5" :key="feature.id" v-for="(feature,key) in features">
					<div class="col-auto">
						<img alt="" class="img-logo" :src="feature.icon">
					</div>
					<div class="col me-0 me-md-5">
						<h3 class="animate__animated animate__fadeInUp" :class="`animate__delay-${key+1}s`">{{ feature.name }}</h3>
						<p class="animate__animated animate__fadeIn" :class="`animate__delay-${key+2}s`">{{ feature.description }}</p>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
export default {
	name: "FeatureSection",
	data() {
		return {
			launchDate: new Date("2022-03-23"),
			activeFeature: {
				id: 0,
				available_at: "2022-03-23",
				name: "Edit3D",
				icon: "fa-check",
				image: "/images/product_pages/page_images/edit3d.jpg",
				description: "Built from the ground up to support the new generation of metaverse experiences. Edit3D allows users of any skill level to quickly and easily create scalable metaverse worlds without any coding knowledge required.",
				src: ""
			},
			features: [
				{
					id: 0,
					available_at: "2022-03-23",
					name: "Scalable Multiplayer",
					icon: "/images/icons/cube.png",
					description: "All metaverse come fully enabled for mass multiplayer and authorative server actions all without ever needing to code.",
					src: ""
				},
				{
					id: 3,
					available_at: "2022-03-23",
					name: "Drag & Drop Editor    ",
					icon: "/images/icons/tick.png",
					description: "Rapidly iterate on ideas & begin with our easy to use 3D editor.",
					src: ""
				},
				{
					id: 2,
					available_at: "2022-04-23",
					name: "Web 2 & 3 Integrations",
					icon: "/images/icons/shield.png",
					description: "Utilize intergrations from web2 or web3 to create interactive experiences with real world interactions.",
					src: ""
				},
			]
		}
	},
	methods: {

		isActive(feature) {
			return this.activeFeature.id === feature.id;
		},

		isAvailable(feature) {
			return this.launchDate >= new Date(feature.available_at).getTime();
		},

		setActiveFeature(feature) {
			this.activeFeature = feature;
		}
	},
}
</script>

<style scoped>

h1 {
	color: #0085FE;
	text-align: center;
}

h1 span {
	color: #45CE7E;
}

.alert-info {
	color: white;
	font-weight: bold;
	background: rgba(0, 0, 0, 0.1);
	cursor: pointer;
	border: 2px solid white;
}

.alert-info.active {
	border: 2px solid #0a9fb9;
}

.blue {
	color: #0a9fb9
}

.alert-info:hover {
	background: rgba(0, 0, 0, 0.8);
}

.feature {
	color: black !important;
}

.feature h3 {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.2em;
}

.features .feature:nth-child(1) h3,
.features .feature:nth-child(2) h3,
.features .feature:nth-child(3) h3,
.features .feature:nth-child(4) h3,
.features .feature:nth-child(5) h3,
.features .feature:nth-child(6) h3 {
	padding-left: 15px;
	border-left: 5px solid #45CE7E;
}
</style>