<template>
	<footer class="container-fluid py-5">

		<div class="container">
			<div class="row">
				<div class="col"></div>
				<div class="col-auto toTop" @click="toTop">
					<i class="fa fa-arrow-up"></i> <span class="ms-3">BACK TO TOP</span>
				</div>
			</div>

			<div class="row mt-5">
				<div class="col-sm-12 col-md-4">
<!--					<h4>LANGUAGES</h4>-->

<!--					<select class="language-control form-control my-5">-->
<!--						<option>English (United Kingdom)</option>-->
<!--					</select>-->
				</div>
				<div class="col-sm-12 col-md-4 offset-0 offset-md-4">
					<h4>SOCIALS</h4>

					<div class="row my-5 text-center">
						<div class="col"><a target="_blank" href='https://medium.com/@Hourglass_AB'>
							<img class="hover-icon" style="width: 45px" alt="medium" src="/images/icons/social/medium.svg"></a>
						</div>
						<div class="col"><a target="_blank" href='https://discord.gg/cgKGFQG4jM'>
							<img class="hover-icon" alt="discord" src="/images/icons/social/discord.svg"></a>
						</div>
						<div class="col"><a target="_blank" href='https://www.linkedin.com/company/alphabatem'>
							<img
									class="hover-icon" alt="linkedin" src="/images/icons/social/LinkedIn.svg"></a></div>
						<div class="col"><a target="_blank" href='https://twitter.com/alpha_batem'>
							<img class="hover-icon" alt="twitter" src="/images/icons/social/twitter.svg"></a>
						</div>
						<div class="col"><a target="_blank" href='https://t.me/alphabatem'>
							<img class="hover-icon" alt="telegram" src="/images/icons/social/telegram.svg"></a>
						</div>
					</div>

				</div>
			</div>
			<hr style="height: 2px">

			<div class="row mt-5">
				<div class="col-3">
					<h4 class="mb-4">PRODUCTS</h4>
					<ul class="links">
						<li>
							<router-link to="/products/edit3d">Edit3D</router-link>
						</li>
						<li>
							<router-link to="/products/blok_host">Blok Host</router-link>
						</li>
						<li>
							<router-link to="/products/hourglass">Hourglass</router-link>
						</li>
					</ul>
				</div>
				<div class="col-3">
					<h4 class="mb-4">RESOURCES</h4>
					<ul class="links">
<!--						<li>-->
<!--							<router-link to="#">Learn Platform</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Community</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Service Status</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Documentation</router-link>-->
<!--						</li>-->
						<li>
							<router-link to="/industries">Case Studies</router-link>
						</li>
<!--						<li>-->
<!--							<router-link to="#">Made with AlphaBatem</router-link>-->
<!--						</li>-->
						<li>
							<router-link to="/roadmap">Roadmap</router-link>
						</li>
						<li>
							<router-link to="#">Whitepaper</router-link>
						</li>
					</ul>
				</div>
				<div class="col-3">
					<h4 class="mb-4">SUPPORT</h4>
					<ul class="links">
<!--						<li>-->
<!--							<router-link to="#">How it works</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Get Help</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Tokenomics</router-link>-->
<!--						</li>-->
						<li>
							<router-link to="/community/faq">FAQ</router-link>
						</li>
						<li>
							<a href="https://docs.alphabatem.com" target="_blank">Documentation</a>
						</li>
						<li>
							<router-link to="#">Contact Us</router-link>
						</li>
					</ul>
				</div>
				<div class="col-3">
					<h4 class="mb-4">ALPHABATEM</h4>
					<ul class="links">
						<li>
							<router-link to="/about">Our Company</router-link>
						</li>
						<li>
							<router-link to="#">Brand Guidelines</router-link>
						</li>
<!--						<li>-->
<!--							<router-link to="#">Press</router-link>-->
<!--						</li>-->
						<li>
							<a href="https://medium.com/@Hourglass_AB" target="_blank">Blog</a>
						</li>
<!--						<li>-->
<!--							<router-link to="#">Partners</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Investors</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Events</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="#">Careers</router-link>-->
<!--						</li>-->
					</ul>
				</div>
			</div>

			<div class="row my-5">
				<div class="col">
					<div class="row">
						<div class="col-12 mb-3">
							<p class="small mb-0">© 2022 AlphaBatem All Rights Reserved.</p>
						</div>
						<div class="col-12">
							<router-link class="d-inline-block me-5 fw-normal" to="/terms-and-conditions">Terms & Conditions</router-link>
							<router-link class="d-inline-block mx-5 fw-normal" to="/privacy">Security</router-link>
							<router-link class="d-inline-block mx-5 fw-normal" to="/privacy">Privacy Policy</router-link>
						</div>
					</div>
				</div>
				<div class="col-auto text-end"><img class="footer-img" src="/images/logos/alphabatem.png"></div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	methods: {
		toTop: function () {
			// document.getElementById('app').scrollIntoView()
			window.scrollTo(0,0)
		}
	}
}
</script>

<style scoped>
.toTop * {
	transition: color 0.3s ease-in-out;
	cursor: pointer;
}

.toTop:hover *, a:hover {
	color: var(--link-primary);
}

footer {
	background: black;
	color: white;
	min-height: 350px;
}

.footer-img {
	width: auto;
	height: 75px;
}

a, li {
	color: white;
}

.hover-icon {
	cursor: pointer;
	transition: transform .2s ease-in-out;
}

.hover-icon:hover {
	transform: scale(1.1);
	filter: invert(47%) sepia(98%) saturate(1206%) hue-rotate(119deg) brightness(99%) contrast(103%);
}

.align-right {
	text-align: right;
}

ul.links {
	padding-left: unset;
	list-style: none;
}

ul.links li {
	font-weight: normal;
	margin-top: 3%;
}

li a {
	text-decoration: none;
}
</style>