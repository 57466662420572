<template>
	<section id="partners" class="container-fluid my-5 py-5">
		<div class="row">
			<div class="col-sm-12 col-md-9 col-lg-7 offset-0 offset-md-1 mb-5">
				<h2 class="mt-5 animate__animated animate__fadeInLeftBig">For people running great companies</h2>
			</div>
			<div class="col-sm-12 col-md-9 col-lg-5 offset-0 offset-md-1 mb-5">
				<h5 class="mt-3">We provide software for the Metaverse needs of modern individuals and founders, so they can get back to doing what they do best — building.</h5>
			</div>
		</div>

		<div class="row my-5" v-if="false">
			<carousel :perPageCustom="[[576, 2], [768, 3], [992, 3]]" :scroll-per-page="false" :autoplayTimeout="0" :autoplay="true" :paginationEnabled="false"
					:navigationEnabled="true"  navigationPrevLabel="" navigationNextLabel="">
				<slide v-for="(partner,key) in partners" :key="key" class="p-3">

					<div class="card h-100 m-2 p-4">
						<div class="card-header row">
							<div class="col-auto">
								<img alt="" :src="partner.src" class="img-logo">
							</div>
							<div class="col">
								<h3 class="mb-2">{{ partner.name }}</h3>
								<p class="mb-0 fw-500">{{ partner.jobTitle }}</p>
							</div>
						</div>
						<div class="card-body">
							<p class="small">{{ partner.description }}</p>
						</div>
					</div>
				</slide>
			</carousel>
		</div>
	</section>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
	name: "ReviewCarousel",
	components: {
		Carousel,
		Slide
	},
	data() {
		return {
			perPage: 3,
			partners: [
				{
					name: "Davea Richardson",
					href: "https://lavendercapital.vc/",
					src: "/images/reviews/davea_richardson.png",
					jobTitle: "Director of Product, Samsung",
					description: "“I've partnered with AlphaBatem twice, & both times they have been really successful. Partnering with AlphaBatem is effortless, seamless and most importantly fun, they just get it !“",
				},
				{
					name: "Varder Hugo",
					href: "https://lavendercapital.vc/",
					src: "/images/reviews/varder_hugo.png",
					jobTitle: "CEO, Lavender Capital",
					description: "“AlphaBatem is the future of the Metaverse. They are an ingenious problem solver being one of the first to create tools for people to be able to build their own metaverse”.",
				},
				{
					name: "Jeremy Walker",
					href: "https://lavendercapital.vc/",
					src: "/images/reviews/jeremy.png",
					jobTitle: "CEO, Minecraft",
					description: "“How do you say world-class experience without saying world-class experience - AlphaBatem“",
				},
				{
					name: "Davea Richardson",
					href: "https://lavendercapital.vc/",
					src: "/images/reviews/davea_richardson.png",
					jobTitle: "Director of Product, Samsung",
					description: "“I've partnered with AlphaBatem twice, & both times they have been really successful. Partnering with AlphaBatem is effortless, seamless and most importantly fun, they just get it !“",
				},
				{
					name: "Varder Hugo",
					href: "https://lavendercapital.vc/",
					src: "/images/reviews/varder_hugo.png",
					jobTitle: "CEO, Lavender Capital",
					description: "“AlphaBatem is the future of the Metaverse. They are an ingenious problem solver being one of the first to create tools for people to be able to build their own metaverse”.",
				},
				{
					name: "Jeremy Walker",
					href: "https://lavendercapital.vc/",
					src: "/images/reviews/jeremy.png",
					jobTitle: "CEO, Minecraft",
					description: "“How do you say world-class experience without saying world-class experience - AlphaBatem“",
				},
			],
		}
	}
}
</script>

<style scoped>
.img-logo {
	border-radius: 100%;
	width: 100%;
	height: 100%;
}

.card-header {
	border: none;
}

.card {
	border-radius: 6px;
}

.card-body p {
	font-weight: 100 !important;
}

</style>