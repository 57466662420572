<template>
	<section id="partners" class="container-fluid my-5 py-5">
		<div class="row">
			<carousel :perPage="4" :scroll-per-page="false" :autoplay="true" :paginationEnabled="false">
				<slide v-for="(partner,key) in partners" :key="key" class="text-center">
<!--					<a target="_blank" :href="partner.href">-->
						<img class="img-fluid" style="max-height: 75px" :alt="partner.name" :src="partner.src">
<!--					</a>-->
				</slide>
			</carousel>
		</div>
	</section>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
	name: "PartnerCarousel",
	components: {
		Carousel,
		Slide
	},
	data() {
		return {
			partners: [
				{
					name: "Lavender Capital",
					href: "https://lavendercapital.vc/",
					src: "/images/logos/lavendar_capital.png"
				},
				{
					name: "Solana",
					href: "https://solana.com",
					src: "/images/logos/solana.png"
				},
				{
					name: "HyperSpace XYZ",
					href: "https://hyperspace.xyz/collection/alphabatem",
					src: "/images/logos/hyperspace.svg"
				},
				{
					name: "ReadyPlayerMe",
					href: "https://readyplayer.me/",
					src: "/images/logos/ready_player_me.svg"
				},
				{
					name: "Solana.FM",
					href: "https://solama.fm/",
					src: "/images/logos/solana_fm.svg"
				},
				// {
				// 	name: "Endstate",
				// 	href: "https://www.endstate.io/",
				// 	src: "/images/partners/endstate.png"
				// },
			],
		}
	}
}
</script>

<style scoped>
img {
	filter: grayscale(100%);
}
</style>