<template>
	<section id="use_cases" class="container">
		<div class="col-12 my-5">
			<h2 class="text-start">Industry Use Cases</h2>
		</div>

		<div class="my-5">
			<div class="row">
				<router-link :to="feature.src" class="feature col-md-4 col-sm-6 mb-5" :key="feature.id" v-for="feature in features">
					<div class="card h-100 p-0">
						<div class="card-header p-0">
							<img :src="feature.image" class="img-fluid w-100">
						</div>
						<div class="card-body">
							<h3>{{ feature.name }}</h3>
							<p class="small">{{ feature.description }}</p>
						</div>
						<div class="card-footer pb-4">
							<router-link class="cta" :to="feature.src">{{ feature.call_to_action }}</router-link>
						</div>
					</div>
				</router-link>


			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "UseCasesSection",
	data() {
		return {
			launchDate: new Date("2022-03-23"),
			activeFeature: {},
			features: [
				{
					id: 0,
					name: "Metaverse",
					icon: "fa-check",
					description: "Unleash creativity with our easy and seamless metaverse builder.",
					call_to_action: "Create your metaverse",
					src: "/industries/metaverse",
					image: "/images/use_cases/Metaverse.jpg"
				},
				{
					id: 3,
					name: "Geospatial",
					icon: "fa-check",
					description: "Collect, analyze and store geographic locations.",
					call_to_action: "Level up your game",
					src: "/industries/geospatial",
					image: "/images/use_cases/Geospatial.jpg"
				},
				{
					id: 2,
					name: "Retail",
					icon: "fa-check",
					description: "Purchase goods & services through our retail shop integrations.",
					call_to_action: "Build world class businesses",
					src: "/industries/retail",
					image: "/images/use_cases/Retail.jpg"
				},
				{
					id: 4,
					name: "Architecture",
					icon: "fa-check",
					description: "Reimagine and build extraordinary structures in the virtual world.",
					call_to_action: "Reinvent your environment",
					src: "/industries/architecture",
					image: "/images/use_cases/Architecture.jpg"
				},
				{
					id: 5,
					name: "Automotive",
					icon: "fa-check",
					description: "Everything you need to build, and grow your team on the metaverse.",
					call_to_action: "Visualize your passions",
					src: "/industries/automotive",
					image: "/images/use_cases/Training.jpg"
				},
				{
					id: 6,
					name: "Digital Signage",
					icon: "fa-check",
					description: "Engage your audience through interactive & immersive experience.",
					call_to_action: "Optimize customer journeys",
					src: "/industries/digital_signage",
					image: "/images/use_cases/Signage.jpg"
				},
			]
		}
	},
	methods: {

		isActive(feature) {
			return this.activeFeature.id === feature.id;
		},

		isAvailable(feature) {
			return this.launchDate >= new Date(feature.available_at).getTime();
		},

		setActiveFeature(feature) {
			this.activeFeature = feature;
		}
	},
	mounted() {
		this.activeFeature = this.features[0];
	},
}
</script>

<style scoped>

h1 {
	text-align: center;
}

.feature {
	cursor: pointer;
}

.cta {
	color: var(--link-cta);
}

.cta:hover {
	color: var(--link-cta);
}

.card {
	transition: all 0.3s ease-in-out;
	border: 3px solid transparent;
}

.card-header {
	overflow: hidden;
}

.card-body h3, img {
	transition: all 0.3s ease-in-out;
}

.card:hover {
	transform: scale(1.01);
	border-color: var(--link-primary);
}

.card:hover .card-header img {
	transform: scale(1.1);
}

.card:hover .card-body h3 {
	color: var(--link-primary);
}
</style>