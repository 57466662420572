<template>
	<section id="hero" class="container-fluid p-0 pb-5 mb-5">
<!--		<div id="img-container">-->
<!--		</div>-->

		<div id="video-container">
			<video autoplay muted loop class="w-100">
				<source src="/images/banner.mp4" type="video/mp4">
			</video>
		</div>

		<div id="text-container">
			<div style="margin-top: 7.5%" class="p-3">
				<div class="offset-0 offset-md-1 col-sm-12 col-md-6 col-lg-4">
					<h1>Build Your</h1>
					<h1>Virtual World.</h1>

					<h3 class="mt-5 fw-light">Thousands of businesses of all sizes – from startups to Fortune 500s – use AlphBatem’s software and APIs to create their metaverse.</h3>
				</div>

				<div class="row mt-5">
					<div class="offset-0 offset-md-1  col-auto">
						<router-link class="btn btn-primary px-5" to="/products/hourglass">Start Now</router-link>
					</div>
					<div class="col"><span class="me-3" style="font-size: 1.2rem; line-height: 3rem; font-weight: normal">POWERED BY:</span> <img src="/images/logos/solana.png"
							alt="solana"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

export default {
	name: "HeroSimple",
	components: {},
	data() {
		return {
			showOverlay: false
		}
	},
	methods: {},
	mounted() {
	}
}
</script>

<style scoped>
#hero {
	height: 720px;
}

#img-container, #text-container {
	width: 100%;
	position: absolute;
	min-height: 780px;
}

#video-container {
	position: absolute;
}

@keyframes pulse {
	0% {
		background-size: 100%;
	}
	50% {
		background-size: 101%;
	}
	100% {
		background-size: 100%;
	}
}

#img-container {
	background: url("/images/blobs/hero_blob.png");
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;

	animation: pulse;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

</style>