<template>
	<div class="home">
		<!--		<Hero :banners="randomizedBanners"></Hero>-->
		<HeroSimple></HeroSimple>

		<PartnerCarousel></PartnerCarousel>

		<UseCasesSection></UseCasesSection>

		<FeatureSection></FeatureSection>

		<EditorFeaturesSection></EditorFeaturesSection>

		<ReviewCarousel></ReviewCarousel>

		<CommunitySection></CommunitySection>

		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";
import CommunitySection from "../components/home/CommunitySection";
import PartnerCarousel from "../components/home/PartnerCarousel";
import UseCasesSection from "../components/home/UseCasesSection";
import FeatureSection from "../components/home/FeatureSection";
import EditorFeaturesSection from "../components/home/EditorFeaturesSection";
import ReviewCarousel from "../components/home/ReviewCarousel";
import HeroSimple from "../components/home/HeroSimple";

export default {
	name: "Home",
	components: {
		HeroSimple,
		ReviewCarousel,
		EditorFeaturesSection,
		FeatureSection,
		UseCasesSection,
		PartnerCarousel,
		CommunitySection,
		Footer,
	},
	data() {
		return {
			heroBanners: [
				// {src: '/content/hero/1.jpg', isVideo: false},
				{src: '/images/banner.mp4', isVideo: true},
				// {src: '/content/hero/2.jpg', isVideo: false},
				// {src: '/content/hero/3.jpg', isVideo: false},
				// {src: '/content/hero/4.jpg', isVideo: false},
				// {src: '/content/hero/1_mov.mp4', isVideo: true}
			],
		}
	},
	computed: {
		randomizedBanners() {
			return this.shuffle(this.heroBanners)
		},
	},
	methods: {
		navigate(url) {
			window.location.href = url;
		},
		shuffle(array) {
			let currentIndex = array.length, randomIndex;
			while (currentIndex !== 0) {
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex--;
				[array[currentIndex], array[randomIndex]] = [
					array[randomIndex], array[currentIndex]];
			}

			return array;
		}
	},
	mounted() {
		const inViewport = (entries) => {
			entries.forEach(entry => {
				entry.target.classList.toggle("show", entry.isIntersecting);
			});
		};

		const Obs = new IntersectionObserver(inViewport);
		const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

// Attach observer to every [data-inviewport] element:
		const ELs_inViewport = document.querySelectorAll('section');
		ELs_inViewport.forEach(EL => {
			Obs.observe(EL, obsOptions);
		});
	}
}
</script>

<style scoped>
h6 {
	text-transform: uppercase;
}

link {
	cursor: pointer;
}

</style>